import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { isDesktopView } from '../../helpers/is-desktop-view.js';

// the "js-is-being-dragged" class is set when the element is being dragged
// use this class to prevent clicks events from doing things
defineCustomElement( 'mr-draggable', {
	attributes: [
		{
			attribute: 'sneaky-placeholder',
			type: 'boolean',
		},
	],
	controller: class extends BaseController {
		render() {
			this.offX = 0;
			this.offY = 0;
			this.dragging = false;
			this.hasDragged = false;

			this.parentScrollHeight = this.el.parentNode.scrollHeight;
			if ( this.el.parentNode.hasAttribute( 'id' ) && 'folder' === this.el.parentNode.id ) {
				this.parentScrollHeight = document.body.offsetHeight;
			}

			// The original classname unmodified by code below
			this.className = this.el.className;

			// set handlers
			this.dragHandler = this.drag();
			this.startHandler = this.startListening( this.dragHandler );
			this.stopHandler = this.stopListening( this.dragHandler );
		}

		bind() {
			if ( isDesktopView() ) {
				this.el.addEventListener( 'mousedown', this.startHandler, false );
				window.addEventListener( 'mouseup', this.stopHandler, false );
			}
		}

		// Start listening to mousemove
		startListening( handler ) {
			return ( ( e ) => {
				if ( !e || !e.which || 1 !== e.which ) { // must be a left click
					return;
				}

				this.offY = e.clientY - parseInt( this.el.offsetTop, 10 );
				this.offX = e.clientX - parseInt( this.el.offsetLeft, 10 );

				this.startY = e.clientY;
				this.startX = e.clientX;
				this.currentY = e.clientY;
				this.currentX = e.clientX;

				this.dragging = true;

				window.addEventListener( 'mousemove', handler, true );

				e.preventDefault();
				e.stopPropagation();
			} );
		}

		// Stop listening to mousemove
		stopListening( handler ) {
			return ( ( e ) => {
				this.dragging = false;
				this.el.classList.remove( 'is-dragged' );

				window.removeEventListener( 'mousemove', handler, true );

				// Wait for the click event to fire before removing the class
				setTimeout( () => {
					this.el.classList.remove( 'js-is-being-dragged' );
				}, 100 );

				e.preventDefault();
				e.stopPropagation();
			} );
		}

		// Move around
		drag() {
			return ( ( e ) => {
				e.preventDefault();
				e.stopPropagation();

				this.currentY = e.clientY;
				this.currentX = e.clientX;

				// Prevent elements from escaping the parent
				let parent = this.el.parentNode;
				let offset = 15;

				if ( parent.hasAttribute( 'id' ) && 'folder' === parent.id ) {
					parent = document.body;
					offset = 0;
				}

				this.el.style.position = 'absolute';

				const okOnTheTop = 0 <= ( e.clientY + offset - this.offY );
				const okOnTheBottom = ( ( e.clientY - this.el.offsetHeight + 50 ) - this.offY ) <= ( this.parentScrollHeight - this.el.offsetHeight );

				if ( okOnTheTop && okOnTheBottom ) {
					this.el.style.top = ( e.clientY - this.offY ) + 'px';
				} else if ( !okOnTheTop ) { // stick to the top
					this.el.style.top = `-${offset}px`;
				} else if ( !okOnTheBottom ) { // stick to the bottom
					this.el.style.top = ( this.parentScrollHeight - 50 ) + 'px';
				}

				const okOnTheLeft = 0 <= ( ( e.clientX + this.el.offsetWidth - 75 ) - this.offX );
				const okOnTheRight = ( ( e.clientX - this.el.offsetWidth + 75 ) - this.offX ) <= ( parent.offsetWidth - this.el.offsetWidth );

				if ( okOnTheLeft && okOnTheRight ) {
					this.el.style.left = ( e.clientX - this.offX ) + 'px';
				} else if ( !okOnTheLeft ) { // stick to the left
					this.el.style.left = '-' + ( this.el.offsetWidth - 75 ) + 'px';
				} else if ( !okOnTheRight ) { // stick to the right
					this.el.style.left = ( parent.offsetWidth - 75 ) + 'px';
				}

				const draggedDistanceY = Math.abs( this.startY - this.currentY );
				const draggedDistanceX = Math.abs( this.startX - this.currentX );
				if ( ( 5 < draggedDistanceY || 5 < draggedDistanceX ) ) {
					this.el.classList.add( 'js-is-being-dragged' );
					this.el.classList.add( 'is-dragged' );
				}

				if ( this.hasDragged || !this.sneakyPlaceholder ) {
					return;
				}

				this.hasDragged = true;

				const sneakyPlaceholder = document.createElement( 'div' );
				sneakyPlaceholder.className = this.className;
				this.el.parentNode.insertBefore( sneakyPlaceholder, this.el );
			} );
		}
	},
} );
