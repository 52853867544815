import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { isBeingDragged } from '../../helpers/is-being-dragged.js';
import { isDesktopView } from '../../helpers/is-desktop-view.js';
import { stack } from './stack.js';
import { finder } from './finder.js';
import { muteAllVideos } from '../../helpers/mute-all-videos.js';

defineCustomElement( 'mr-file-toggle', {
	attributes: [
		{
			attribute: 'for',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		render() {}

		bind() {

			this.on( 'click .js-file-toggle', ( e, target ) => {
				e.preventDefault();
				e.stopPropagation();

				// Don't open when dragging
				if ( isBeingDragged( target ) ) {
					return;
				}

				const forEl = document.querySelector( '#' + this.for );
				if ( !forEl ) {
					return;
				}

				forEl.style.display = 'block';

				if ( isDesktopView() ) {
					this.addWindow( forEl );
				} else {
					this.addColumn( forEl );
				}
			}, this.el );
		}

		addWindow( element ) {
			if ( !this.hasBeenPositioned ) {
				const startPosition = stack.newPosition();
				this.hasBeenPositioned = true;

				element.style.position = 'absolute';
				element.style.left = `${startPosition.x}px`;
				element.style.top = `${startPosition.y}px`;

				( Array.from( element.querySelectorAll( '.js-folder-close' ) ) ).forEach( ( closer ) => {
					closer.addEventListener( 'click', ( e ) => {
						e.preventDefault();
						e.stopPropagation();

						// Don't close windows when dragging
						if ( isBeingDragged( element ) ) {
							return;
						}

						// We might have closed a file with a playing video.
						muteAllVideos();

						if ( 'block' === element.style.display ) {
							element.style.display = 'none';
						} else {
							element.style.display = 'block';
						}
					} );
				} );

				( Array.from( element.querySelectorAll( '.js-folder-focus' ) ) ).forEach( ( focusser ) => {
					focusser.addEventListener( 'click', () => {
						stack.pushElToTop( element );
					} );
				} );
			}

			stack.pushElToTop( element );

			// We might open a file with a video, while another video is still playing.
			muteAllVideos();
		}

		addColumn( element ) {
			// We might open a file with a video, while another video is still playing.
			muteAllVideos();
			finder.elementInFolderClicked( this.el, element, true );
		}
	},
} );
