/*
 * Mr Group
 *
 * Change title of Group page when hovering (or scrolling) over certain projects
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-group', {
	attributes: [],
	controller: class extends BaseController {
		resolve() {
			return super.resolve( true );
		}

		init() {
			this.elements = {};

			this.elements.hit = this.el.querySelector( '.js-group-mobile-hit' );
			this.elements.projects = Array.from( this.el.querySelectorAll( '.js-group__project' ) );
			this.elements.texts = Array.from( this.el.querySelectorAll( '.js-group__text' ) );
			this.elements.title = this.el.querySelector( '.js-group__title' );

			this.getHitPosition();

			this.originalTitle = this.elements.title.textContent || null;
		}

		bind() {
			this.elements.projects.forEach( ( project ) => {
				this.on( 'mouseenter', () => {
					const projectTitle = project.getAttribute( 'data-title' );

					if ( !projectTitle ) {
						return;
					}

					this.updateTitle( projectTitle );
				}, project );

				this.on( 'mouseleave', () => {
					this.resetTitle();
				}, project );
			} );

			this.on( 'mr-window-watcher:scroll', () => {
				if ( 768 < window.innerWidth ) {
					return;
				}

				this.checkHits();
			}, window );

			this.on( 'mr-window-watcher:resize', () => {
				this.getHitPosition();
			}, window );
		}

		updateTitle( label ) {
			this.elements.title.textContent = label;
		}

		resetTitle() {
			this.elements.title.textContent = this.originalTitle;
		}

		checkHits() {
			if ( !this.elements.projects || !this.elements.projects.length || !this.hitPosition || 768 < window.innerWidth ) {
				return;
			}

			this.resetProjects();

			const first = this.elements.projects[0].getBoundingClientRect();
			const last = this.elements.projects[this.elements.projects.length - 1].getBoundingClientRect();

			if ( first && first.top > this.hitPosition ) {
				this.resetTitle();

				return;
			}

			if ( last && last.bottom < this.hitPosition ) {
				this.resetTitle();

				return;
			}

			for ( let i = 0; i < this.elements.projects.length; i++ ) {
				const project = this.elements.projects[i];
				const rect = project.getBoundingClientRect();
				const title = project.getAttribute( 'data-title' );

				if ( !rect || rect.top > this.hitPosition || rect.bottom < ( this.hitPosition + 20 ) ) {
					continue;
				}

				project.classList.add( 'is-active' );
				this.updateTitle( title );
			}

			// now check texts hits
			if ( !this.elements.texts || !this.elements.texts.length ) {
				return;
			}

			for ( let j = 0; j < this.elements.texts.length; j++ ) {
				const text = this.elements.texts[j];
				const rect = text.getBoundingClientRect();

				if ( !rect || rect.top > this.hitPosition || rect.bottom < ( this.hitPosition + 20 ) ) {
					continue;
				}

				this.resetTitle();
			}
		}

		resetProjects() {
			for ( let i = 0; i < this.elements.projects.length; i++ ) {
				if ( this.elements.projects[i].classList.contains( 'is-active' ) ) {
					this.elements.projects[i].classList.remove( 'is-active' );
				}
			}
		}

		getHitPosition() {
			if ( !this.elements.hit ) {
				return;
			}

			const hitRect = this.elements.hit.getBoundingClientRect();

			this.hitPosition = ( hitRect.top + hitRect.height ) || null;
		}
	},
} );
