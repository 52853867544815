class Finder {

	constructor() {
		document.addEventListener( 'mr-window-watcher:resize-width', () => {
			const desktop = document.getElementById( 'folder' );
			if ( !desktop ) {
				return;
			}

			desktop.removeAttribute( 'style' );
		} );
	}

	elementInFolderClicked( element, column, isFile = false ) {
		let tempCurrentLevel = 1;

		// Check if has parent
		const parentWindow = element.closest( '.js-folder-window' );
		if ( parentWindow && parentWindow.hasAttribute( 'data-level' ) ) {
			// Check which level attributes
			tempCurrentLevel = parseInt( parentWindow.dataset.level, 10 ) + 1;

			// Search and disable active folder
			const activeFolder = parentWindow.querySelector( '.folder.is-active' );
			if ( activeFolder ) {
				activeFolder.classList.remove( 'is-active' );
			}
		} else {
			// no parent so toplevel element
			// lets remove possible toplevel active folders and items
			const activeFolder = document.querySelector( '.folder.is-active' );
			if ( activeFolder ) {
				activeFolder.classList.remove( 'is-active' );
			}
		}

		// Search and destroy all ancestor levels
		const columns = document.getElementsByClassName( 'js-folder-window' );
		if ( 0 < columns.length ) {
			for ( let i = 0; i < columns.length; i++ ) {
				if ( columns[i].hasAttribute( 'data-level' ) && parseInt( columns[i].dataset.level, 10 ) >= tempCurrentLevel ) {
					columns[i].style.display = 'none';
					columns[i].removeAttribute( 'data-level' );
				}
			}
		}

		// set this element
		element.parentNode.classList.add( 'is-active' );

		// Add column
		column.style.position = 'absolute';
		column.style.left = `${tempCurrentLevel * 200}px`;
		column.style.top = '0px';
		column.style.height = '100%';
		column.setAttribute( 'data-level', tempCurrentLevel );

		// resize desktop
		const desktop = document.getElementById( 'folder' );
		if ( desktop ) {
			if ( isFile ) {
				const w = Math.max( document.documentElement.clientWidth, window.innerWidth || 0 );
				desktop.style.width = `${( ( tempCurrentLevel - 1 ) * 200 ) + w}px`;
			} else {
				desktop.style.width = `${( ( tempCurrentLevel + 1 ) * 200 ) + 80}px`;
			}
		}

		const stackWrapper = document.getElementById( 'js-finder-wrapper' );
		if ( stackWrapper ) {
			stackWrapper.scrollTo( {
				top: 0,
				left: ( ( tempCurrentLevel * 200 ) - 20 ),
				behavior: 'smooth',
			} );
		}
	}
}

export const finder = new Finder();
