import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { isBeingDragged } from '../../helpers/is-being-dragged.js';
import { isDesktopView } from '../../helpers/is-desktop-view.js';
import { stack } from './stack.js';
import { finder } from './finder.js';

defineCustomElement( 'mr-folder-link', {
	attributes: [
		{
			attribute: 'for',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		render() {}

		init() {
			this.scrolling = false;
		}

		bind() {
			this.on( 'click .js-folder-link', ( e, target ) => {
				// Don't open links when dragging
				if ( isBeingDragged( target ) ) {
					e.preventDefault();
					e.stopPropagation();

					return;
				}

				if ( !this.for ) {
					return; // nothing to do here
				}

				if ( !target || !target.dataset || !target.dataset.folderId ) {
					return; // nothing to do here
				}

				try {
					const folder = document.querySelector( '#' + this.for );
					folder.style.display = 'block';

					if ( isDesktopView() ) {
						if ( folder.getAttribute( 'folder-rendered' ) ) {
							stack.pushElToTop( folder );
							// focus window
							e.preventDefault();
							e.stopPropagation();

							// scroll to top
							this.scrollToTop();

							return;
						}

						this.addWindow( folder );
					} else {
						this.addColumn( folder );
					}

					this.fetchPartial( '/wp-json/folder/data.json?folder_id=' + target.dataset.folderId, folder );

					e.preventDefault();
					e.stopPropagation();
				} catch ( err ) {
					e.preventDefault();
					e.stopPropagation();

					// send to bugsnag, but rethrow for now
					throw err;
				}
			}, this.el );

			this.on( 'mr-window-watcher:resize-width', () => {
				this.reset();
			}, window );
		}

		reset() {
			const folder = document.querySelector( '#' + this.for );

			if ( folder && folder.getAttribute( 'folder-rendered' ) ) {
				folder.removeAttribute( 'folder-rendered' );
			}
		}

		addWindow( folder ) {
			const startPosition = stack.newPosition();

			folder.style.position = 'absolute';
			folder.style.left = `${startPosition.x}px`;
			folder.style.top = `${startPosition.y}px`;

			stack.pushElToTop( folder );
		}

		addColumn( folder ) {
			finder.elementInFolderClicked( this.el, folder );
		}

		fetchPartial( href, folder ) {
			if ( !href || href.includes( '#' ) ) {
				return;
			}

			const headers = new Headers();
			const request = new Request( `${href}`, {
				method: 'GET',
				headers: headers,
				cache: 'default',
			} );

			this.scrollToTop();

			fetch( request ).then( ( response ) => {
				if ( !response.ok ) {
					throw new Error( `files-partial : ${response.status} ${response.statusText}` );
				}

				return response.json();
			} ).then( ( data ) => {
				const folderContent = folder.querySelector( '.js-folder-content' );
				const folderMeta = folder.querySelector( '.js-folder-meta' );

				folder.setAttribute( 'folder-rendered', 'folder-rendered' );

				folderContent.innerHTML = data.html_screen + data.html_windows;

				const existingFolder = document.querySelector( '#folder' );
				const newWindows = folderContent.querySelectorAll( '.js-folder-window' );
				if ( existingFolder && newWindows ) {
					const newWindowEls = Array.from( newWindows );
					newWindowEls.forEach( ( el ) => {
						existingFolder.appendChild( el );
					} );

					if ( 1 === newWindowEls.length ) {
						folderMeta.innerHTML = '1 item';
					} else {
						folderMeta.innerHTML = `${newWindowEls.length} items`;
					}
				}

				this.scrollToTop();

				if ( isDesktopView() ) {
					( Array.from( folder.querySelectorAll( '.js-folder-close' ) ) ).forEach( ( closer ) => {
						closer.addEventListener( 'click', ( e ) => {
							e.preventDefault();
							e.stopPropagation();

							// Don't close windows when dragging
							if ( isBeingDragged( folder ) ) {
								return;
							}

							folder.style.display = 'none';
						} );
					} );

					( Array.from( folder.querySelectorAll( '.js-folder-focus' ) ) ).forEach( ( focusser ) => {
						focusser.parentNode.addEventListener( 'mousedown', () => {
							stack.pushElToTop( folder );
						} );
					} );
				}

			} ).catch( ( err ) => {

				// send to bugsnag, but rethrow for now
				throw err;

			} );
		}

		scrollToTop() {
			const mainContent = document.getElementById( 'main-content' );

			if ( !mainContent ) {
				return;
			}

			mainContent.scrollIntoView( {
				behavior: 'smooth',
				block: 'start',
			} );
		}
	},
} );
