export const isBeingDragged = ( target ) => {
	if ( !target || !target.classList ) {
		return false;
	}

	if ( target.classList.contains( 'js-is-being-dragged' ) ) {
		return true;
	}

	return target.parentNode && isBeingDragged( target.parentNode );
};
