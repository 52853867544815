const root = 'https://stream.mrhenry.be';

const startHlsLiveStream = ( video, src ) => {
	if ( video.canPlayType( 'application/vnd.apple.mpegurl' ) ) {

		video.src = src;
		video.addEventListener( 'loadedmetadata', () => {
			video.play().then( () => {
				console.log( 'start playing...' );
				startViewCounterLoop();
			}, ( err ) => {
				console.log( err );
			} );
		} );

	// eslint-disable-next-line no-undef
	} else if ( Hls.isSupported() ) {

		// eslint-disable-next-line no-undef
		const hls = new Hls( {
			liveSyncDurationCount: 2,
			xhrSetup: function( xhr ) {
				xhr.withCredentials = true; // do send cookies
			},
			fetchSetup: function( context, initParams ) {
				// Always send cookies, even for cross-origin calls.
				initParams.credentials = 'include';

				return new Request( context.url, initParams );
			},
		} );

		hls.loadSource( src );
		hls.attachMedia( video );

		// eslint-disable-next-line no-undef
		hls.on( Hls.Events.MANIFEST_PARSED, () => {
			video.play().then( () => {
				console.log( 'start playing...' );
				startViewCounterLoop();
			}, ( err ) => {
				console.log( err );
			} );
		} );

		// eslint-disable-next-line no-undef
		hls.on( Hls.Events.ERROR, ( event, data ) => {
			console.log( event, data );
		} );

	}
};

const startViewCounterLoop = () => {
	const clear = setInterval( () => {
		// Try a cookie!
		const cookieValue = document.cookie.replace( /(?:(?:^|.*;\s*)hls-view-counter\s*=\s*([^;]*).*$)|^.*$/, '$1' );
		if ( cookieValue ) {
			const viewCounterEl = document.getElementById( 'hls-view-counter' );

			if ( viewCounterEl ) {
				viewCounterEl.innerHTML = cookieValue;
			}

			return;
		}

		// Fallback to API call
		fetch( root + '/' + window.location.host.split( '.' ).join( '-' ) + '/view-count.json', {
			credentials: 'include',
			mode: 'cors',
		} ).then( ( resp ) => {
			return resp.json();
		} ).then( ( data ) => {
			const viewCounterEl = document.getElementById( 'hls-view-counter' );

			if ( viewCounterEl ) {
				viewCounterEl.innerHTML = data.view_count;
			}
		} ).catch( ( err ) => {
			clearInterval( clear );
			console.log( err );
		} );

	}, 3000 );
};

let init = () => {
	const videoID = 'hls-live-stream';
	const video = document.getElementById( videoID );
	if ( !video ) {
		return;
	}

	init = () => {}; // noop


	startHlsLiveStream( video, root + '/' + window.location.host.split( '.' ).join( '-' ) + '/livestream.m3u8' );
};

document.addEventListener( 'readystatechange', () => {
	init();
}, false );

init();
