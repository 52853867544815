/*
 * Mr Mute Toggle 🔈/🔇
 *
 * Send out mute toggle events for videos to listen to
 *
 */

export class MrMuteToggle extends HTMLElement {
	constructor() {
		super();

		this._stateChangeHandler = ( e ) => {
			if ( !e.detail || !e.detail.id ) {
				return;
			}

			const button = this.querySelector( '.js-mute-toggle-button' );
			if ( !button ) {
				return;
			}

			const videoId = button.getAttribute( 'data-video-id' ) || false;
			if ( e.detail.id !== videoId ) {
				return;
			}

			const video = document.getElementById( videoId );
			if ( !video ) {
				return;
			}

			if ( video.muted ) {
				button.classList.remove( 'is-toggled' );
			} else {
				button.classList.add( 'is-toggled' );
			}
		};

		this._muteButtonClickedHandler = ( e ) => {
			if ( e.metaKey ) {
				return;
			}

			const button = this.querySelector( '.js-mute-toggle-button' );
			if ( !button ) {
				return;
			}

			const videoId = button.getAttribute( 'data-video-id' ) || false;
			if ( !videoId ) {
				return;
			}

			const video = document.getElementById( videoId );
			if ( !video ) {
				return;
			}

			// Toggle
			video.muted = !video.muted;

			if ( video.muted ) {
				button.classList.remove( 'is-toggled' );
				button.innerHTML = button.getAttribute( 'data-label-init' );
			} else {
				button.classList.add( 'is-toggled' );
				button.innerHTML = button.getAttribute( 'data-label-toggled' );
			}
		};
	}

	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		this.addEventListener( 'click', this._muteButtonClickedHandler );
		window.addEventListener( 'mr-file-video:state-update', this._stateChangeHandler );
	}

	_removeEventListeners() {
		this.removeEventListener( 'click', this._muteButtonClickedHandler );
		window.removeEventListener( 'mr-file-video:state-update', this._stateChangeHandler );
	}
}

customElements.define( 'mr-mute-toggle', MrMuteToggle );
