import { isDesktopView } from '../../helpers/is-desktop-view.js';

class Stack {

	constructor() {
		this.init();
		this.bind();
	}

	init() {
		this.stack = null;
		this.nextStartPosition = null;
		this.shiftLeft = true;
		this.popupWidth = 600;
		this.popupHeight = 460;
		this.margin = 48;
		this.extraMargin = 48;
		this.shiftAmount = 35;
		this.resetShift = 1;
		this.windowWidth = window.innerWidth;

		if ( window.matchMedia( '(min-width: 1816px)' ).matches ) {
			this.extraMargin = ( ( this.windowWidth - 1720 ) / 2 );
		}

		const x = this._reCalculateX();
		const y = this.margin; // top margin

		this.stack = 98;
		this.currentPosition = {
			x: x,
			y: y,
		};
	}

	bind() {
		document.addEventListener( 'mr-window-watcher:resize-width', () => {
			if ( isDesktopView() ) {
				this.init();
			}
		} );
	}

	pushElToTop( el ) {
		if ( !el ) {
			return;
		}

		this.stack += 1;
		el.style['z-index'] = this.stack;
		el.setAttribute( 'data-z-index', this.stack );
	}

	newPosition() {
		// make a copy, else you'll get the shifted position
		const position = {
			x: this.currentPosition.x,
			y: this.currentPosition.y,
		};

		this._shiftPosition();

		return position;
	}

	currentPosition() {
		return this.currentPosition;
	}

	_shiftPosition() {
		this.currentPosition.x = this.currentPosition.x - this.shiftAmount; // shift left
		if ( this.currentPosition.x < ( this.margin + this.extraMargin ) ) { // too much left
			this.resetShift += 1;
			// reset left position but with less margin left
			this.currentPosition.x = this._reCalculateX();
			// reset top position
			this.currentPosition.y = this.margin;

			// Let's check X again and check if the reset needs to be resetted
			if ( 1 < this.resetShift && this.currentPosition.x < ( this.margin + this.extraMargin ) ) {
				// reset the reset
				this.resetShift = 1;
				this.currentPosition.x = this._reCalculateX();
			}

			return;
		}

		// if x is fine, update Y
		this.currentPosition.y = this.currentPosition.y + this.shiftAmount; // shift down
		if ( this.currentPosition.y > ( document.body.offsetHeight - this.popupHeight - this.margin ) ) {
			// When y is outside constrains, reset again
			this.resetShift += 1;
			this.currentPosition.x = this._reCalculateX();
			this.currentPosition.y = this.margin;
		}
	}


	_reCalculateX() {
		// wrapper width - ( popup width + right margin + extra margin )
		return ( this.windowWidth - ( this.popupWidth + ( this.margin * this.resetShift ) + this.extraMargin ) );
	}
}

export const stack = new Stack();
