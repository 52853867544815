import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrNavigationModal extends MrModalDialog {
	constructor() {
		super();
	}

	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-site-navigation__modal' );
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'is-showing-site-navigation__modal' );
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		const effects = [];

		const toggleCloseButton = document.getElementById( 'site-navigation__modal__toggle--close' );
		if ( toggleCloseButton ) {
			effects.push( new KeyframeEffect(
				toggleCloseButton,
				[
					{
						color: window.getComputedStyle( toggleCloseButton ).color,
						transform: 'rotate(0deg) translateZ(0)',
					},
					{
						color: 'var(--color-white)',
						transform: 'rotate(45deg) translateZ(0)',
					},
				],
				{
					duration: 128,
					easing: 'ease-in',
					fill: 'forwards',
				}
			) );
		}

		const toggleOpenButton = document.getElementById( 'site-navigation__modal__toggle--open' );
		if ( toggleOpenButton ) {
			effects.push( new KeyframeEffect(
				toggleOpenButton,
				[
					{
						color: window.getComputedStyle( toggleOpenButton ).color,
						transform: 'rotate(0deg) translateZ(0)',
					},
					{
						color: 'transparent',
						transform: 'rotate(45deg) translateZ(0)',
					},
				],
				{
					duration: 128,
					easing: 'ease-in',
					fill: 'forwards',
				}
			) );
		}

		const mainContent = document.getElementById( 'main-content' );
		if ( mainContent ) {
			effects.push( new KeyframeEffect(
				mainContent,
				[
					{
						filter: 'none',
					},
					{
						filter: 'var(--site-navigation-active-background-blur)',
					},
				],
				{
					duration: 128,
					easing: 'ease-in',
					fill: 'forwards',
				}
			) );
		}

		effects.push( new KeyframeEffect(
			document.getElementById( 'site-navigation__modal__inner' ),
			[
				{
					opacity: 0,
				},
				{
					opacity: 1,
				},
			],
			{
				delay: 64,
				duration: 128,
				easing: 'ease-in-out',
				fill: 'forwards',
			}
		) );

		return effects;
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		const effects = [];

		const toggleCloseButton = document.getElementById( 'site-navigation__modal__toggle--close' );
		if ( toggleCloseButton ) {
			effects.push( new KeyframeEffect(
				toggleCloseButton,
				[
					{
						color: 'var(--color-white)',
						transform: 'rotate(45deg) translateZ(0)',
					},
					{
						color: toggleCloseButton.style.color,
						transform: 'rotate(0deg) translateZ(0)',
					},
				],
				{
					duration: 128,
					easing: 'ease-in',
					fill: 'forwards',
				}
			) );
		}

		const toggleOpenButton = document.getElementById( 'site-navigation__modal__toggle--open' );
		if ( toggleOpenButton ) {
			effects.push( new KeyframeEffect(
				toggleOpenButton,
				[
					{
						color: 'transparent',
						transform: 'rotate(45deg) translateZ(0)',
					},
					{
						color: toggleOpenButton.style.color,
						transform: 'rotate(0deg) translateZ(0)',
					},
				],
				{
					duration: 128,
					easing: 'ease-in',
					fill: 'forwards',
				}
			) );
		}

		const mainContent = document.getElementById( 'main-content' );
		if ( mainContent ) {
			effects.push( new KeyframeEffect(
				mainContent,
				[
					{
						filter: 'var(--site-navigation-active-background-blur)',
					},
					{
						filter: 'none',
					},
				],
				{
					duration: 128,
					easing: 'ease-in',
					fill: 'forwards',
				}
			) );
		}

		effects.push( new KeyframeEffect(
			document.getElementById( 'site-navigation__modal__inner' ),
			[
				{
					opacity: 1,
				},
				{
					opacity: 0,
				},
			],
			{
				duration: 128,
				easing: 'ease-in',
				fill: 'forwards',
			}
		) );

		return effects;
	}
}

customElements.define( 'mr-navigation-modal', MrNavigationModal );
