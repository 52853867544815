/*
 * Mr Window Watcher 🖥👀
 *
 * Sending out Custom Events to be (re-)used in JS
 * Setting body calss variations to be used in CSS
 *
 * -----
 *
 * Elements we need:
 *
 * The <body>, because state modifier classes are added to it
 *
 * -----
 *
 * Events we listen for:
 *
 * A: Scroll Event
 * B: Resize Event
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-window-watcher', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.body = document.querySelector( 'body' );

			this.lastWidth = this.getCurrentWidth();

			this.scrollHandler( window.scrollY );
		}

		// [A]
		scrollHandler( scrollPosition ) {
			if ( isNaN( scrollPosition ) || !this.elements.body ) {
				return;
			}

			if ( isNaN( this.currentScrollPosition ) ) {
				this.currentScrollPosition = 0;
			}

			if ( 50 < scrollPosition ) {
				this.elements.body.classList.add( 'is-scrolled' );

				this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:scroll', {
					bubbles: true,
					cancelable: true,
					detail: {
						scrolled: true,
						position: scrollPosition,
					},
				} ) );
			} else {
				this.elements.body.classList.remove( 'is-scrolled' );

				this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:scroll', {
					bubbles: true,
					cancelable: true,
					detail: {
						scrolled: false,
						position: scrollPosition,
					},
				} ) );
			}

			this.currentScrollPosition = scrollPosition;
		}

		// [B]
		resizeHandler() {
			this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:resize', {
				bubbles: true,
				cancelable: true,
			} ) );

			// Check if width has resized
			if ( this.lastWidth !== this.getCurrentWidth() ) {
				this.lastWidth = this.getCurrentWidth();

				this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:resize-width', {
					bubbles: true,
					cancelable: true,
				} ) );
			}
		}

		getCurrentWidth() {
			// source: https://gist.github.com/joshcarr/2f861bd37c3d0df40b30
			return window.innerWidth || document.documentElement.clientWidth || document.documentElement.getElementsByTagName( 'body' )[0].clientWidth;
		}

		bind() {
			// [A]
			let scrollThrottle = false;

			this.on( 'scroll', () => {
				if ( !this.elements.body ) {
					this.unbind( 'scroll' );

					return;
				}

				if ( scrollThrottle ) {
					return;
				}

				scrollThrottle = true;
				this.scrollHandler( window.scrollY );

				setTimeout( () => {
					scrollThrottle = false;
					this.scrollHandler( window.scrollY );
				}, 112 );
			}, window, {
				passive: true,
			} );

			// [B]
			let resizeThrottle = false;

			this.on( 'resize', () => {
				if ( !this.elements.body ) {
					this.unbind( 'resize' );

					return;
				}

				if ( resizeThrottle ) {
					return;
				}

				resizeThrottle = true;
				this.resizeHandler();

				setTimeout( () => {
					resizeThrottle = false;
					this.resizeHandler();
				}, 240 );
			}, window, {
				passive: true,
			} );
		}
	},
} );
