import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { isDesktopView } from '../../helpers/is-desktop-view.js';

defineCustomElement( 'mr-files-stack', {
	attributes: [],
	controller: class extends BaseController {
		reset() {
			this.isDesktopView = isDesktopView();

			const windows = document.getElementsByClassName( 'js-folder-window' );
			if ( 0 < windows.length ) {
				for ( let i = 0; i < windows.length; i++ ) {
					windows[i].style.display = 'none';
				}
			}

			const draggeables = document.getElementsByClassName( 'js-folder-item' );
			if ( 0 < draggeables.length ) {
				for ( let i = 0; i < draggeables.length; i++ ) {
					if ( 'DIV' === draggeables[i].tagName ) {
						// Sneaky Placeholder => Remove it
						draggeables[i].parentNode.removeChild( draggeables[i] );
					} else {
						// Folder => Reset it
						draggeables[i].style.position = '';
						draggeables[i].style.top = '';
						draggeables[i].style.left = '';
					}
				}
			}
		}

		bind() {
			this.on( 'mr-window-watcher:resize-width', () => {
				if ( 'undefined' === typeof this.isDesktopView ) {
					this.isDesktopView = isDesktopView();
				}

				if ( this.isDesktopView !== isDesktopView() ) {
					// Refresh
					location.reload();

					return;
				}

				this.reset();
			}, window );


			this.on( 'keyup', () => {
				if ( !isDesktopView() ) {
					return;
				}

				const key = event.key || event.keyCode;

				if ( 'Escape' === key || 'Esc' === key || 27 === key ) {
					this.closeTopWindow();
				}
			}, document );
		}

		closeTopWindow() {
			const windows = document.getElementsByClassName( 'js-folder-window' );
			if ( 1 > windows.length ) {
				return;
			}

			let highestElement = false;
			let highestZIndex = 0;
			for ( let i = 0; i < windows.length; i++ ) {
				if ( windows[i].hasAttribute( 'data-z-index' ) && highestZIndex < parseInt( windows[i].getAttribute( 'data-z-index' ), 10 ) ) {
					highestZIndex = parseInt( windows[i].getAttribute( 'data-z-index' ), 10 );
					highestElement = windows[i];
				}
			}

			if ( highestElement ) {
				highestElement.style.display = 'none';
				highestElement.removeAttribute( 'data-z-index' );
			}
		}

	},
} );
