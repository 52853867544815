import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-stream-translator', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.items = Array.from( this.el.querySelectorAll( '.js-stream-item' ) );

			this.hitPosition = 200; // default

			this.getHitPosition();
			this.forceScrollThrough();
		}

		bind() {
			this.on( 'mr-window-watcher:scroll', () => {
				this.checkHits();
			}, window );

			this.on( 'mr-window-watcher:resize', () => {
				this.getHitPosition();
				this.forceScrollThrough();
			}, window );
		}

		getHitPosition() {
			if ( 1380 <= window.innerWidth ) {
				this.hitPosition = 160;
			} else {
				this.hitPosition = 200;
			}
		}

		forceScrollThrough() {
			const stream = this.el.querySelector( '.js-stream' );
			const list = this.el.querySelector( '.js-stream-list' );
			const scrollTop = window.pageYOffset || 0;

			if ( !stream || !list || !this.hitPosition ) {
				return;
			}

			const listBounds = list.getBoundingClientRect();
			const height = window.innerHeight + listBounds.height + ( listBounds.top + scrollTop - this.hitPosition + 52 );

			if ( !height || isNaN( height ) ) {
				return;
			}

			stream.style.minHeight = `${height}px`;
		}

		checkHits() {
			if ( !this.elements.items || !this.elements.items.length || !this.hitPosition ) {
				return;
			}

			this.elements.items.forEach( ( item ) => {
				const rect = item.getBoundingClientRect();

				if ( !rect ) {
					return;
				}

				if ( ( rect.top + ( rect.height / 2 ) ) <= this.hitPosition ) {
					item.classList.add( 'is-active' );
				} else {
					item.classList.remove( 'is-active' );
				}
			} );
		}
	},
} );
