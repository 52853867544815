export const muteAllVideos = () => {
	( Array.from( document.querySelectorAll( 'video' ) ) ).forEach( ( video ) => {
		video.muted = true;

		const id = video.id;
		if ( id ) {
			window.dispatchEvent( new CustomEvent( 'mr-file-video:state-update', {
				bubbles: true,
				cancelable: true,
				detail: {
					id: id,
				},
			} ) );
		}
	} );
};
