/*
 * Mr Video Background 👯‍♂️
 *
 * Create background-repeat: repeat-y behaviour with a video
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-video-background', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.canvas = this.el.querySelector( '#js-video-background-canvas' );
			this.elements.source = this.el.querySelector( '.js-video-background-source' );

			if ( !this.elements.canvas || !this.elements.source ) {
				return;
			}

			this.context = this.elements.canvas.getContext( '2d' );
			this.count = 1;

			this.intervals = [];
		}

		bind() {
			this.on( 'play', () => {
				this.prepare();
			}, this.elements.source );

			this.on( 'mr-window-watcher:resize', () => {
				this.prepare();
			}, window );
		}

		prepare() {
			if ( !window.matchMedia( '(max-aspect-ratio: 1/1)' ).matches ) {
				return;
			}

			this.clearAllIntervals();
			this.context.clearRect( 0, 0, this.elements.canvas.width, this.elements.canvas.height );

			this.elements.canvas.width = window.innerWidth;
			this.elements.canvas.height = this.elements.canvas.offsetHeight;

			// make sure you set this element to the available height (with or without tabbar)
			this.el.style.height = window.innerHeight;

			// How many videos are needed to fill element height?
			this.count = Math.ceil( this.elements.canvas.clientHeight / this.elements.source.clientHeight );

			// No extra videos needed if video height is >= element height
			if ( isNaN( this.count ) || 1 >= this.count ) {
				return;
			}

			// Draw extra videos, skip 1 because original video is already there
			for ( let i = 1; i < this.count; i++ ) {
				this.throttledDraw( i );
			}
		}

		clearAllIntervals() {
			if ( !this.intervals.length ) {
				return;
			}

			this.intervals.forEach( ( interval, index, object ) => {
				clearInterval( interval );
				object.splice( index, 1 );
			} );
		}

		throttledDraw( i ) {
			const interval = setInterval( () => {
				this.drawVideo( this.elements.source.clientHeight * i );
			}, 1000 / 30 );

			this.intervals.push( interval );
		}

		drawVideo( yPos ) {
			this.context.drawImage(
				this.elements.source, // source
				0, // x pos
				yPos, // y pos
				this.elements.canvas.width, // width
				this.elements.source.clientHeight // height
			);
		}
	},
} );
