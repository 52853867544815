import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

/* Attributes:
 *
 * fade (boolean)
 * → whether to use a fadeout transition or not
 *
 * image (string)
 * → the image source for the crumbs
 *
 * interval (int)
 * → the throttle time in between dropping crumbs (ms)
 *
 * lifespan (int)
 * → the amount of time a crumb remains on the screen (ms)
 *
 * rotate (boolean)
 * → whether to rotate each crumb on placing it or not
 *
 */

defineCustomElement( 'mr-magic-cursor', {
	attributes: [
		{
			attribute: 'data-fade',
			type: 'boolean',
		},
		{
			attribute: 'data-image',
			type: 'string',
		},
		{
			attribute: 'data-interval',
			type: 'int',
		},
		{
			attribute: 'data-lifespan',
			type: 'int',
		},
		{
			attribute: 'data-rotate',
			type: 'boolean',
		},
	],
	controller: class extends BaseController {
		init() {
			// if rotation is active, set start value of 0
			if ( this.dataRotate ) {
				this.rotation = 0;
			}

			this.calcImageDimensions();
		}

		calcImageDimensions() {
			// calculate image dimensions of invisible example image
			// so we have to do this only once
			const leader = this.el.querySelector( '.js-magic-cursor-leader' );

			if ( leader ) {
				this.imageHeight = leader.offsetHeight;
				this.imageWidth = leader.offsetHeight;

				// we don't need this anymore
				this.el.removeChild( leader );
			}
		}

		bind() {
			let isTouch = false;
			let moveThrottle = false;

			// no need for mousemove stuff if we're on a touch device
			this.on( 'touchstart', () => {
				isTouch = true;
			}, window, {
				passive: true,
				useCapture: true,
			} );

			this.on( 'mousemove', ( e ) => {
				if ( isTouch ) {
					this.unbind();

					return;
				}

				if ( moveThrottle || !this.imageHeight || !this.imageWidth ) {
					return;
				}

				moveThrottle = true;
				this.dropCrumb( e.clientX, e.clientY );

				setTimeout( () => {
					moveThrottle = false;
					this.dropCrumb( e.clientX, e.clientY );
				}, this.dataInterval );
			}, window, {
				passive: true,
			} );
		}

		dropCrumb( mouseX, mouseY ) {
			// 0 → Create a new Image object
			const crumb = new Image();

			// 3 → Start counting down to remove the crumb
			const startCountingDown = () => {
				this.hideTimeout = setTimeout( () => {
					this.el.removeChild( crumb );
				}, this.dataLifespan );
			};

			// 2 → Add the crumb to the screen
			const placeImage = () => {
				this.el.appendChild( crumb );

				startCountingDown();
			};

			/* 1 → Give the crumb everything it needs:
			 *
			 * - image source
			 * - classname
			 * - correct position (with a css transform)
			 * - optional: rotate the image
			 * - optional: add animation class and duration
			 *
			 */
			const buildImage = () => {
				crumb.setAttribute( 'src', this.dataImage );
				crumb.classList.add( 'magic-cursor-item' );
				crumb.style.transform = `translate3d(${mouseX + 5}px, ${mouseY + 5}px, 0)`;

				if ( this.dataRotate ) {
					crumb.style.transformOrigin = `
						${( mouseX + 5 ) + ( this.imageWidth / 2 )}px
						${( mouseY + 5 ) + ( this.imageHeight / 2 )}px 0`;

					if ( -360 === this.rotation ) {
						this.rotation = 0;
					} else {
						this.rotation = this.rotation - 6;
					}

					crumb.style.transform = `
						rotate(${this.rotation}deg)
						translate3d(${mouseX}px, ${mouseY}px, 0)`;
				}

				if ( this.dataFade ) {
					crumb.style.animationDuration = `${this.dataLifespan}ms`;
					crumb.classList.add( 'is-fading' );
				}

				placeImage();
			};

			buildImage();
		}
	},
} );
