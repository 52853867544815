import '@mrhenry/wp--bugsnag-config';
import '@mrhenry/wp--autoplay-in-view';

import './modules/backflash-frame-tool';
import './modules/brush-nugget';
import './modules/group';
import './modules/hls';
import './modules/input-sink';
import './modules/magic-cursor';
import './modules/mr-navigation-modal';
import './modules/mute-toggle';
import './modules/nightfall-3d-model';
import './modules/stream-translator';
import './modules/video-background';
import './modules/window-watcher';
import './modules/windows98/draggeable';
import './modules/windows98/file-toggle';
import './modules/windows98/files-stack';
import './modules/windows98/folder-link';


import { MrCarousel, MrMatchesValue } from '@mrhenry/wp--mr-interactive';

customElements.define( 'mr-carousel', MrCarousel );
customElements.define( 'mr-matches-value', MrMatchesValue );
