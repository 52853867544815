function selectProduct() {
	document.querySelectorAll( '[data-model]' ).forEach( ( model ) => {
		model.removeAttribute( 'data-is-current' );
	} );

	const input = document.querySelector<HTMLInputElement>( 'input:checked.nightfall-3d-model__select-product__item__input' );
	if ( input ) {
		const model = document.querySelector( `[data-model="${input.value}"]` );
		model?.setAttribute( 'data-is-current', '' );
	}
}

window.addEventListener( 'load', () => {
	document.querySelectorAll( '.nightfall-3d-model__select-product__item__input' ).forEach( ( input ) => {
		input.addEventListener( 'change', selectProduct );
	} );
} );
