import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

/* Attributes:
 *
 * data (string, json)
 * → recording data object
 *
 */

defineCustomElement( 'mr-brush-nugget', {
	attributes: [
		{
			attribute: 'data',
			type: 'json',
		},
	],
	controller: class extends BaseController {
		resolve() {
			return super.resolve( true );
		}

		init() {
			this.hasPlayed = false;

			this.canvas = this.el.getElementsByClassName( 'js-brush-nugget__canvas' )[0];
			this.ctx = this.canvas.getContext( '2d' );

			// Draw settings
			this.settings = {};
			this.settings.densityMin = 12;
			this.settings.densityMax = 20;
			this.settings.sizeMin = 0.2;
			this.settings.sizeMax = 0.7;
			this.settings.roundMin = 2.2;
			this.settings.roundMax = 2.8;
			this.settings.opacity = 0.4;
			this.settings.distanceMin = 2;
			this.settings.distanceMax = 30;

			// Draw cache
			this.cache = {};
			this.cache.lastPoint = null;
		}

		bind() {
			this.on( 'mr-window-watcher:scroll', () => {
				if ( !this.hasPlayed ) {
					this.triggerPlay();
				}
			}, window );

			this.on( 'click', () => {
				this.triggerPlay();
			}, this.canvas );
		}

		triggerPlay() {
			if ( this.isAnyPartOfElementInViewport() ) {
				this.hasPlayed = true;
				this.off( 'mr-window-watcher:scroll', window );
				this.startPlaying();
			}
		}

		isAnyPartOfElementInViewport() {
			// Source: https://gist.github.com/davidtheclark/5515733#gistcomment-2113205
			const rect = this.el.getBoundingClientRect();

			const windowHeight = ( window.innerHeight || document.documentElement.clientHeight );
			const windowWidth = ( window.innerWidth || document.documentElement.clientWidth );

			const vertInView = ( rect.top <= windowHeight ) && ( 0 <= ( rect.top + rect.height ) );
			const horInView = ( rect.left <= windowWidth ) && ( 0 <= ( rect.left + rect.width ) );

			if ( vertInView && horInView ) {
				return true;
			}

			return false;
		}

		startPlaying() {
			// First clear cache and ctx
			this.cache.lastPoint = null;
			this.startTime = new Date();
			this.ctx.clearRect( 0, 0, this.canvas.width, this.canvas.height );

			// The recording var has an array of actions sets
			if ( this.data && this.data.hasOwnProperty( 'actionsets' ) ) {
				// Clone the actionsets from the data attribute so we can modify the array
				this.actionsets = JSON.parse( JSON.stringify( this.data.actionsets ) );
				window.requestAnimationFrame( () => {
					this.animate();
				} );
			}
		}

		animate() {
			// If actionssets aint empty we should loop through it
			this.elapsedIterations++;

			if ( 0 < this.actionsets.length ) {
				// First let's get the difference between startTime and currentTime
				const timeElapsed = new Date() - this.startTime;

				// Loop through actionSets
				for ( let i = 0; i < this.actionsets.length; i++ ) {
					// Check wether or not the actionSet interval has elapsed the current time
					if ( this.actionsets[i].interval > timeElapsed ) {
						// If it hasnt elapsed, lets break out the For Loop
						break;
					}

					// If not, we should draw the actions, remove the actionset and make loop index smaller
					this.drawActions( this.actionsets[i].actions );
					this.actionsets.shift();
					i--;
				}

				// Loop again
				window.requestAnimationFrame( () => {
					this.animate();
				} );
			}
		}

		drawActions( actions ) {
			for ( let i = 1; i < actions.length; i++ ) {
				this.drawAction( actions[i] );
			}
		}

		drawAction( action ) {
			let x = action.x;
			let y = action.y;

			if ( null === this.cache.lastPoint ) {
				this.cache.lastPoint = action;
			}

			switch ( action.type ) {
				case 0: // moveto
					this.ctx.beginPath();
					this.ctx.moveTo( x, y );
					break;
				case 1: // lineto
					const dist = this.distanceBetween( this.cache.lastPoint, action );
					const angle = this.angleBetween( this.cache.lastPoint, action );

					for ( let i = 0; i < dist; i++ ) {
						x = this.cache.lastPoint.x + ( Math.sin( angle ) * i ) - 25;
						y = this.cache.lastPoint.y + ( Math.cos( angle ) * i ) - 25;
						this.draw( x, y, dist );
					}
					break;
			}
			this.cache.lastPoint = action;
		}

		draw( x, y, distance ) {
			const distanceNormalized = ( this.settings.distanceMax - ( Math.min( Math.max( distance, this.settings.distanceMin ), this.settings.distanceMax ) - this.settings.distanceMin ) ) / this.settings.distanceMax;
			const density = Math.round( distanceNormalized * this.settings.densityMax + this.settings.densityMin );
			const radius = distanceNormalized * ( this.settings.roundMax - this.settings.roundMin ) + this.settings.roundMin;

			for ( let i = density; i--; ) {
				const angle = this.getRandomFloat( 0, Math.PI * 2 );
				this.ctx.globalAlpha = this.getRandomFloat( 0, this.settings.opacity );
				this.ctx.fillRect(
					x + radius * Math.cos( angle ),
					y + radius * Math.sin( angle ),
					this.getRandomFloat( this.settings.sizeMin, this.settings.sizeMax ), this.getRandomFloat( this.settings.sizeMin, this.settings.sizeMax )
				);
			}
		}

		distanceBetween( point1, point2 ) {
			return Math.sqrt( Math.pow( point2.x - point1.x, 2 ) + Math.pow( point2.y - point1.y, 2 ) );
		}

		angleBetween( point1, point2 ) {
			return Math.atan2( point2.x - point1.x, point2.y - point1.y );
		}

		getRandomFloat( min, max ) {
			return Math.random() * ( max - min ) + min;
		}
	},
} );
